/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState, useRef, Suspense } from 'react';
import { useRouter } from 'next/router';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { routePaths } from '@/constants/routePaths';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { usePageViewCall } from '@/utils/analytics/usePageViewCall';
import { pageTypes } from '@/utils/analytics/legacyUtils/pageTypes';
import { getSeoPageType } from '@/utils/analytics/legacyUtils/getSeoPageType';
import { getPageName } from '@/utils/analytics/legacyUtils/getPageName';
import { getAnalyticsRelativeComplementOf } from '@/utils/analytics/getAnalyticsRelativeComplementOf';
import { siteSections } from '@/utils/siteSections';
import { useGetContentStackLandingPageData } from '../api/getContentStackLandingPageData';
import { CMSModularHandler } from '../components/CMSModularHandler';
import { CMSSeoTags } from '@/features/contentstack/components/CMSSeoTags/CMSSeoTags';
import { CMSBottomModularHandler } from '../components/CMSBottomModularHandler';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import dynamic from 'next/dynamic';
import { Drawer } from '@/components/Drawer/Drawer';
import { type AddCertonaItemObjectType } from '@/features/certona';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useSelector } from 'react-redux';
import { selectOrderDetailsFromState } from '@/constants/selectors';
import styles from './LandingPage.module.scss';
import { ScrolledPartCardSkeleton } from '@/features/certona';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { certonaPageType } from '@/constants/certonaPageType';
import { useContentStackLivePreview } from '@/hooks/useContentStackLivePreview';

type Props = {
  pageName?: string;
};

const LazyMiniCartFlyoutComp = dynamic(
  () => import('@/features/miniCartFlyout').then((mod) => mod.MiniCartFlyoutComp),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

export const LandingPageComp = (props: Props) => {
  const hasSetupAnalytics = useAnalytics();
  const pageViewCall = usePageViewCall();
  const router = useRouter();

  const [pageViewCalled, setPageViewStatus] = useState(false);
  const initialPageName = router.query.pageName?.toString() || props.pageName;
  const cleanPageName = initialPageName?.replace('/:', ':');
  const pageName = `lp/${cleanPageName ?? ''}`;

  const isRecommendationsLandingPage = router.asPath.includes(
    routePaths.landingPageRecommendations
  );

  const [showCertonaMiniCartFlyoutModal, setShowCertonaMiniCartFlyoutModal] =
    useState<boolean>(false);
  const [certonaRecommendationAddedToCart, setCertonaRecommendationAddedToCart] =
    useState<boolean>(false);
  const [addedCertonaItemObject, setAddedCertonaItemObject] =
    useState<AddCertonaItemObjectType | null>(null);
  const closeStoreModal = () => {
    if (addedCertonaItemObject) {
      setCertonaRecommendationAddedToCart(false);
      setAddedCertonaItemObject(null);
    }
  };
  const toggleCertonaMiniCartFlyout = () => {
    setShowCertonaMiniCartFlyoutModal(() => !showCertonaMiniCartFlyoutModal);
    closeStoreModal();
  };
  useEffect(() => {
    if (addedCertonaItemObject) {
      setShowCertonaMiniCartFlyoutModal(true);
    }
  }, [addedCertonaItemObject]);
  const lazyMiniCartModalRef = useRef<HTMLDivElement>(null);
  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const orderDetailsFromState = useSelector(selectOrderDetailsFromState);

  const {
    data: landingPageData,
    isLoading: isLoadingLandingPageData,
    isSuccess: isLandingPageQuerySuccess,
    error: landingPageQueryError,
  } = useGetContentStackLandingPageData({
    pageName: initialPageName,
  });
  const pageNotFound = Boolean(landingPageQueryError);

  const vehicleId = headerData?.vehicleMap.catalogVehicleId || '';
  const certonaRecommendations = useGetCertonaRecommendations(
    {
      certonaType: certonaPageType.recommendations,
      vehicleID: vehicleId,
      recommendations: true,
    },
    [certonaPageType, vehicleId],
    true
  );

  useContentStackLivePreview({ enabled: true });

  useEffect(() => {
    if (pageNotFound) {
      void router.replace('/NotFound');
    }
  }, [pageNotFound, router]);

  useEffect(() => {
    if (!pageViewCalled && hasSetupAnalytics && landingPageData) {
      const pageNameForSEO = `lp:${cleanPageName ?? ''}${
        router.asPath.includes('#') ? router.asPath.slice(router.asPath.indexOf('#')) : ''
      }`;

      const initialName = router.asPath.replace('/', '');
      const name = initialName.replace('/:', ':');
      const pageSpecificAnalyticsData = {
        pageName: getPageName(pageTypes.landingPage, pageNameForSEO),
        siteSection: siteSections.siteSectionlandingPage,
        pageType: pageTypes.landingPage,
        pageHierarchy: `Home|${
          name.includes('-')
            ? name.replace(/-/g, ' ').replace(/\b([a-z])/g, (x: string) => x.toUpperCase())
            : name.replace(/\b([a-z])/g, (x: string) => x.toUpperCase())
        }`,
        seoPageType: getSeoPageType(pageTypes.landingPage, pageName),
      };
      pageViewCall(
        pageSpecificAnalyticsData,
        getAnalyticsRelativeComplementOf(pageSpecificAnalyticsData, undefined)
      );
      setPageViewStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageData, hasSetupAnalytics]);

  useEffect(() => {
    setPageViewStatus(false);
  }, [router]);
  return (
    <div>
      {isLoadingLandingPageData ? (
        <div>
          <ProgressModal noScroll={true} />
        </div>
      ) : null}
      {landingPageData && isLandingPageQuerySuccess && (
        <>
          <CMSModularHandler
            content={landingPageData.modularBlocks}
            certonaRecommendations={certonaRecommendations}
            setAddedCertonaItemObject={setAddedCertonaItemObject}
            setCertonaRecommendationAddedToCart={setCertonaRecommendationAddedToCart}
            onlyShowAvailableProductRecommendations={isRecommendationsLandingPage}
            LoadingSkeleton={ScrolledPartCardSkeleton}
            isTopContent
          />
          <div className={styles.miniCartPopup}>
            <Drawer
              data-testid="mini-cart-flyout"
              anchor={'right'}
              open={showCertonaMiniCartFlyoutModal}
              onClose={toggleCertonaMiniCartFlyout}
              PaperProps={{ className: styles.customPaperDrawer }}
              drawerRef={lazyMiniCartModalRef}
            >
              <TrapFocus open={showCertonaMiniCartFlyoutModal}>
                <div className={styles.miniCartFlyoutDialog} role="dialog" aria-modal="true">
                  <Suspense>
                    {showCertonaMiniCartFlyoutModal && addedCertonaItemObject && (
                      <LazyMiniCartFlyoutComp
                        handleClose={toggleCertonaMiniCartFlyout}
                        isModalOpen={showCertonaMiniCartFlyoutModal}
                        image={addedCertonaItemObject.productImage}
                        successData={addedCertonaItemObject.cartSuccessData}
                        productDetails={{
                          productName: addedCertonaItemObject.productDetails.productName,
                          certonaItemAddedTocart: certonaRecommendationAddedToCart,
                          productPartType: addedCertonaItemObject.productDetails.partType,
                          productId: addedCertonaItemObject.productDetails.productId,
                          originalPartTypeId: addedCertonaItemObject.productDetails.partType,
                          productAvailabilityInfo: {
                            aZBadgesFlagVO: null,
                            dealsInfo: undefined,
                            skuPricingAndAvailability: undefined,
                          },
                        }}
                        miniCartData={miniCartMap}
                        closeStoreModal={closeStoreModal}
                        cartUrl={addedCertonaItemObject.submitToCart}
                        productTitle={addedCertonaItemObject.productTitle}
                        pageName={addedCertonaItemObject.pageName}
                        orderDetailsFromState={orderDetailsFromState}
                        skuId={addedCertonaItemObject.productDetails.skuId}
                        parentRef={lazyMiniCartModalRef}
                      />
                    )}
                  </Suspense>
                </div>
              </TrapFocus>
            </Drawer>
          </div>
        </>
      )}
      {landingPageData && isLandingPageQuerySuccess && landingPageData.bottomModularBlocks && (
        <CMSBottomModularHandler content={landingPageData.bottomModularBlocks} />
      )}
      {isLandingPageQuerySuccess && landingPageData && landingPageData.seoTags && (
        <CMSSeoTags seoData={landingPageData.seoTags} />
      )}
    </div>
  );
};
