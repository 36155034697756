/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import * as React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import ContentContainer from '../ContentContainer';
import styles from './styles.module.scss';
import { CheckoutSmartLink } from './CheckoutSmartLink';
import { Close } from '@az/starc-ui-icons';
import { Icon } from '@az/starc-ui';
import { scrollIntoViewWithOffset } from '@/utils/domutils';

export type NotificationType = 'error' | 'warn' | 'info' | 'success';

type Props = {
  message: string | React.ReactNode;
  subMessage?: React.ReactNode | string;
  type: NotificationType;
  itemLevel?: boolean;
  scrollToNotification?: boolean;
  id?: string;
  customClasses?: any;
  notificationContainerClassName?: any;
  errorFont?: boolean;
  checkoutSmartLink?: boolean;
  smartLinkMessage?: string;
  withPadding?: boolean;
  hasCloseIcon?: boolean;
};

interface State {
  open: boolean;
}

class NotificationBlock extends React.Component<Props, State> {
  state: State = {
    open: true,
  };
  static defaultProps: {
    itemLevel: boolean;
    scrollToNotification: boolean;
    subMessage: string;
    hasCloseIcon?: boolean;
  } = {
    subMessage: '',
    itemLevel: false,
    scrollToNotification: false,
    hasCloseIcon: false,
  };

  componentDidMount() {
    const errorMessage = document?.getElementById('error-message');
    if (errorMessage) {
      errorMessage.setAttribute('tabindex', '0');
      errorMessage.focus();
    }
    if (this.props.scrollToNotification && this.alertRef.current) {
      setTimeout(
        () =>
          scrollIntoViewWithOffset(this.alertRef.current!, 100, {
            behavior: 'smooth',
          }),
        200
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const scrollToNotificationActiveAndChanged =
      this.props.scrollToNotification &&
      this.props.scrollToNotification !== prevProps.scrollToNotification;
    if (scrollToNotificationActiveAndChanged && this.alertRef.current) {
      setTimeout(
        () =>
          scrollIntoViewWithOffset(this.alertRef.current!, 100, {
            behavior: 'smooth',
          }),
        200
      );
    }
  }

  alertRef: {
    current: null | HTMLDivElement;
  } = React.createRef<HTMLDivElement>();

  ariaLabel: string =
    typeof this.props.message === 'string' && this.props.message?.includes('<phone>')
      ? `${this.props.message.substring(
          0,
          this.props.message.indexOf('<phone>') - 1
        )} ${this.props.message.substring(
          this.props.message.indexOf('<phone>') + 7,
          this.props.message.indexOf('<phone>') + 21
        )} `
      : '';

  isChrome =
    !__IS_SERVER__ && /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  handleOnCloseClick = () => {
    return this.setState({
      open: false,
    });
  };

  render() {
    const { withPadding = true, errorFont = false, hasCloseIcon } = this.props;
    const { open } = this.state;
    return open ? (
      <>
        {this.ariaLabel.length > 0 && !this.isChrome ? (
          <div
            ref={this.alertRef}
            tabIndex={-1}
            role={'alert'}
            className={cx(styles.notificationContainer, this.props.notificationContainerClassName)}
            aria-label={this.ariaLabel}
          >
            <ContentContainer
              type="shadowed"
              withPadding={withPadding}
              withMargin={!this.props.itemLevel}
              className={
                this.props.customClasses
                  ? `${
                      this.props.itemLevel
                        ? styles[`${String(this.props.type)}ItemLevel`]
                        : styles[`${String(this.props.type)}Shadow`]
                    } ${this.props.customClasses}`
                  : `${
                      this.props.itemLevel
                        ? styles[`${String(this.props.type)}ItemLevel`]
                        : styles[`${String(this.props.type)}Shadow`]
                    }`
              }
              id="notificationAlert"
              data-testid="notificationAlert"
            >
              <div className={cx(azCommonStyles['az-caption'], styles.notificationMessage)}>
                <span className={styles[this.props.type]} />
                <span
                  id={this.props.id ? this.props.id : ''}
                  className={this.props.checkoutSmartLink || errorFont ? styles.errorFont : ''}
                  aria-hidden={true}
                >
                  {this.props.message}{' '}
                  {this.props.checkoutSmartLink && (
                    <CheckoutSmartLink smartLinkMessage={this.props.smartLinkMessage} />
                  )}
                </span>{' '}
                {hasCloseIcon && (
                  <Icon
                    size={5}
                    svg={Close}
                    attributes={{
                      style: { color: '#202124', margin: '6px' },
                      onClick: this.handleOnCloseClick,
                    }}
                  />
                )}
              </div>
            </ContentContainer>
          </div>
        ) : (
          <div
            ref={this.alertRef}
            tabIndex={-1}
            role={'alert'}
            className={cx(styles.notificationContainer, this.props.notificationContainerClassName)}
          >
            <ContentContainer
              type="shadowed"
              withPadding={withPadding}
              withMargin={!this.props.itemLevel}
              className={
                this.props.checkoutSmartLink
                  ? `${styles.smartLinkItemLevel}`
                  : this.props.customClasses
                  ? `${
                      this.props.itemLevel
                        ? styles[`${String(this.props.type)}ItemLevel`]
                        : styles[`${String(this.props.type)}Shadow`]
                    } ${this.props.customClasses}`
                  : `${
                      this.props.itemLevel
                        ? styles[`${String(this.props.type)}ItemLevel`]
                        : styles[`${String(this.props.type)}Shadow`]
                    }`
              }
              id="notificationAlert"
              data-testid="notificationAlert"
            >
              <div className={cx(azCommonStyles['az-caption'], styles.notificationMessage)}>
                <span className={styles[this.props.type]} />
                <span
                  id={this.props.id ? this.props.id : ''}
                  className={this.props.checkoutSmartLink || errorFont ? styles.errorFont : ''}
                  aria-hidden={true}
                >
                  {this.props.message} {this.props.subMessage}
                  {this.props.checkoutSmartLink && (
                    <CheckoutSmartLink smartLinkMessage={this.props.smartLinkMessage} />
                  )}
                </span>
                {hasCloseIcon && (
                  <Icon
                    svg={Close}
                    size={5}
                    attributes={{
                      style: { color: '#202124', margin: '6px' },
                      onClick: this.handleOnCloseClick,
                    }}
                  />
                )}
              </div>
            </ContentContainer>
          </div>
        )}
      </>
    ) : null;
  }
}

export default NotificationBlock;
