/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const scrollIntoViewWithOffset = (
  element: HTMLElement,
  offset: number,
  options?: ScrollToOptions
) => {
  const bodyTop = document.body.getBoundingClientRect().top;
  const elementTop = element.getBoundingClientRect().top;
  window.scrollTo({
    ...options,
    top: elementTop - bodyTop - offset,
  });
};
